import React, { useState } from 'react'
import ConsentBannerWrapper from './components/consentBannerWrapper/consentBannerWrapper'
import LoadAdobe from './utils/LoadAdobe'

const LoadConsent = ({ element, props }) => {
  const { pageContext } = props
  const [allowTracking, setAllowTracking] = useState(false)

  return (
    <>
      {allowTracking && <LoadAdobe />}
      <ConsentBannerWrapper setAllowTracking={setAllowTracking} locale={pageContext.locale} />
      {element}
    </>
  )
}

export default LoadConsent
