import React from 'react'
import { Helmet } from 'react-helmet'

const LoadAdobe = () => (
  <Helmet>
    <script src={process.env.GATSBY_ADOBE_ANALYTICS_URL} />
  </Helmet>
)

export default LoadAdobe
