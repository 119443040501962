import { useStaticQuery, graphql } from 'gatsby'

const useWebsiteSettings = locale => {
  const { allContentfulWebsiteSettings } = useStaticQuery(graphql`
    query WebsiteSettingsQuery {
      allContentfulWebsiteSettings {
        nodes {
          websiteTitle
          locale: node_locale
          logo {
            fluid(maxWidth: 456, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          ...FragmentMainNavigation
          ...FragmentFooter
          enableSocialMediaIcons
          socialMediaLink1 {
            ...FragmentLink
          }
          socialMediaLink1Icon {
            file {
              url
            }
          }
          socialMediaLink2 {
            ...FragmentLink
          }
          socialMediaLink2Icon {
            file {
              url
            }
          }
          enableSocialMediaIcons
          socialMediaLink3 {
            ...FragmentLink
          }
          socialMediaLink3Icon {
            file {
              url
            }
          }
          socialMediaLink4 {
            ...FragmentLink
          }
          socialMediaLink4Icon {
            file {
              url
            }
          }
          cookiePolicyBannerDescription {
            raw
          }
          cookiePolicyBannerAccept
          cookiePolicyBannerDecline
          cookiePolicyBannerAcceptAriaLabel
          cookiePolicyBannerDeclineAriaLabel
        }
      }
    }
  `)

  return allContentfulWebsiteSettings.nodes.find(element => element.locale.includes(locale))
}

export default useWebsiteSettings
