import React from 'react'
import { INLINES, MARKS } from '@contentful/rich-text-types'
import { renderRichText as renderContentfulRichText } from 'gatsby-source-contentful/rich-text'

const renderMark = {
  [MARKS.BOLD]: text => <strong>{text}</strong>,
  [MARKS.ITALIC]: text => <em>{text}</em>,
  [MARKS.UNDERLINE]: text => <u>{text}</u>,
}

const renderText = text => {
  return text.split('\n').reduce((children, textSegment, index) => {
    // eslint-disable-next-line react/no-array-index-key
    return [...children, index > 0 && <br key={index} />, textSegment]
  }, [])
}

const renderNode = {
  [INLINES.HYPERLINK]: (node, children) => {
    const { data } = node

    const target =
      (data.uri.includes('http') &&
        // data.uri.includes(process.env.GATSBY_ORIGIN) &&
        !data.uri.endsWith('.pdf')) ||
      data.uri.includes('mailto:') ||
      data.uri[0] === '#'
        ? '_self'
        : '_blank'

    return (
      <a href={data.uri} target={target} rel="noopener noreferrer">
        {children}
      </a>
    )
  },
  [INLINES.EMBEDDED_ENTRY]: ({ data, nodeType }) => {
    const type = data.target?.__typename || nodeType
    const entry = data.target

    switch (type) {
      case 'ContentfulAnchor':
        // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
        return <a id={entry.anchorName} />
      default:
        // return <>{JSON.stringify(node, null, 2)}</>
        return null
    }
  },
}

const options = {
  renderMark,
  renderText,
  renderNode,
}

const renderRichText = body => renderContentfulRichText(body, options)

export { renderRichText }
