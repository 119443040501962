import { css } from 'styled-components'

export const gothamLight = css`
  font-family: 'Gotham Light', sans-serif;
  font-weight: normal !important;
`
export const gothamBook = css`
  font-family: 'Gotham Book', sans-serif;
  font-weight: normal !important;
`

export const gothamBookItalic = css`
  font-family: 'Gotham Book', sans-serif;
  font-weight: normal !important;
  font-style: italic;
`

export const gothamMedium = css`
  font-family: 'Gotham Medium', sans-serif;
  font-weight: normal !important;
`
export const gothamBold = css`
  font-family: 'Gotham Bold', sans-serif;
  font-weight: normal !important;
`
export const brandonGrotesqueBold = css`
  font-family: 'Brandon Grotesque Bold';
  font-weight: normal !important;
`

export const brandonGrotesqueRegular = css`
  font-family: 'Brandon Grotesque Regular';
  font-weight: normal !important;
`
export const manus = css`
  font-family: 'Manus RT Costa 211015';
  font-weight: normal !important;
`
