export const color = {
  alabaster: '#fafafa',
  black: '#000000',
  brightRed: '#b51144',
  costaGray: '#f4f3ef',
  costaOrange: '#ce4b27',
  costaRed: '#6d1f37',
  darkGray: '#404042',
  darkRed: '#91022E',
  disabledGray: '#9b9b9b',
  empress: '#747474',
  focusBlue: '#0066ff',
  lightGray: '#dddddd',
  lightPink: '#ffdddd',
  lightRed: '#cf1d1a',
  mediumGray: '#9e9e9e',
  paleGray: '#f4f3ef',
  redPink: '#bf0341',
  successGreen: '#4e8342',
  white: '#ffffff',
  whiteSmoke: '#f6f6f6',
}
