import { css } from 'styled-components'
import { above } from './breakpoints'
import { brandonGrotesqueBold, gothamBook, gothamMedium } from './fonts'

const baseHeadingStyle = css`
  ${brandonGrotesqueBold};
`
export const headingOne = css`
  ${baseHeadingStyle};
  font-size: 35px;
  line-height: 38.5px;

  ${above.tablet`
  font-size: 70px;
  line-height: 77px;
  `}
`

export const headingTwo = css`
  ${baseHeadingStyle};
  font-size: 33px;
  line-height: 36px;

  ${above.tablet`
  font-size: 60px;
  line-height: 66px;
  `}
`

export const headingThree = css`
  ${baseHeadingStyle};
  font-size: 30px;
  line-height: 33px;

  ${above.tablet`
  font-size: 50px;
  line-height: 55px;
  `}
`

export const headingFour = css`
  ${baseHeadingStyle};
  font-size: 25px;
  line-height: 27.5px;

  ${above.tablet`
  font-size: 40px;
  line-height: 44px;
  `}
`

export const headingFive = css`
  ${baseHeadingStyle};
  font-size: 20px;
  line-height: 22px;

  ${above.tablet`
  font-size: 30px;
  line-height: 36px;
  `}
`

export const headingSixBrandon = css`
  ${baseHeadingStyle};
  font-size: 18px;
  line-height: 26px;

  ${above.tablet`
  font-size: 24px;
  line-height: 30px;
  `}
`

export const headingSixGotham = css`
  ${gothamBook};
  font-size: 18px;
  line-height: 26px;

  ${above.tablet`
  font-size: 24px;
  line-height: 30px;
  `}
`

export const headingSixGothamMedium = css`
  ${gothamMedium};
  font-size: 18px;
  line-height: 26px;

  ${above.tablet`
  font-size: 24px;
  line-height: 30px;
  `}
`

export const baseBodyText = css`
  ${gothamBook};
  font-size: 16px;
  line-height: 24px;

  ${above.tablet`
    font-size: 18px;
    line-height: 28px;
  `}
`

export const smallBodyText = css`
  ${gothamBook};
  font-size: 14px;
  line-height: 22px;

  ${above.tablet`
  font-size: 16px;
  line-height: 24px;
  `}
`

export const xsmallBodyText = css`
  ${gothamBook};
  font-size: 12px;
  line-height: 18px;

  ${above.tablet`
  font-size: 14px;
  line-height: 22px;
  `}
`

export const xxsmallBodyText = css`
  ${gothamBook};
  font-size: 12px;
  line-height: 18px;
`
