const cookie = {
  hasChosenCookieSettings() {
    return !!document.cookie.match(/costa_cookieconsent/)
  },

  areCookiesAllowed() {
    return !!document.cookie.match(/costa_cookieconsent=allow/)
  },

  setConsentCookie(value) {
    const date = new Date()
    date.setTime(date.getTime() + 31536000000) // 1 year
    const expires = `; expires = ${date.toUTCString()}`
    document.cookie = `costa_cookieconsent = ${value}${expires}; path = /`
  },
}

export default cookie
